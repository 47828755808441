<template>
  <div class="page-simplePage-list">
    <a-card :bordered="false">
      list
      <render-dom :render="useMethodsRender"></render-dom>
    </a-card>
  </div>
</template>

<script>
export default {
  methods: {
    useMethodsRender() {
      return (
        <a-button
          onClick={() => {
            this.toDetail()
          }}
        >
          跳转详情
        </a-button>
      )
    },
    toDetail() {
      this.$router.push('/m/simplePage/main/detail')
    },
  },
}
</script>

<style lang="less" scoped></style>
